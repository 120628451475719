import React, { useEffect, useRef, useState } from 'react';
import {
    Grid,
    Card,
    Typography,
    CardHeader,
    CardContent,
    Button,
    Chip,
    CardActions,
    Avatar,
    Alert,
    Menu,
    IconButton,
    MenuItem,
    Tooltip,
    Box,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilePdf,
    faMegaphone,
    faPencil,
    faTags,
    faTrash,
    faVideo
} from '@fortawesome/pro-light-svg-icons';
import { useContext } from 'react';
import { UserContext } from '../store/UserContext';
import { LayoutContext } from '../store/LayoutContext';
import setLanguageText from '../language/setLanguageText';
import * as Yup from "yup"
import GlobalDialog from '../reusable/GlobalDialog';
import { useMutation } from 'react-query';
import useActivityLog from '../users/activity/useActivityLog';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ResourceForm from './ResourceForm';
import { Formik } from 'formik';
import DialogLoader from '../reusable/DialogLoader';
import useSignedUrl from '../hooks/useSignedUrl';
import { Storage } from 'aws-amplify';
import { Document, Page } from 'react-pdf';

const EditResource = ({ initialValues, categories, editResource, setEditResource, refreshResources, refetchCategories }) => {

    //console.log(initialValues)

    const { user } = useContext(UserContext);
    const [openLevels, setOpenLevels] = useState(["0"]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    //image uploads
    const [file, setFile] = useState()
    const [fileUrl, setFileUrl] = useState()
    const [slider, setSlider] = useState()
    const [preview, setPreview] = useState();
    const [loading, setLoading] = useState()

    const formRef = useRef();

    const resouceValidation = Yup.object({
        resource_cat_id: Yup
            .number('Select category')
            .required('Please select a category'),
        resource_file_name: Yup
            .string('Enter the resource name')
            .required('Resource name is required'),
        resource_description: Yup
            .string('Enter resource description')
    });

    const {
        updateActivityLog,
        //loggingError,
        //loggingResponse
    } = useActivityLog();
    const { getSignedUrl } = useSignedUrl();

    //sert category tree structure
    useEffect(() => {
        if (categories) {
            let levels = ["0"]
            categories.forEach((item) => {
                levels.push(JSON.stringify(item.resource_cat_id))
            })
            setOpenLevels(levels)
            //console.log(response)
        }
    }, [categories])

    useEffect(() => {
        if (Object.values(categories).length && initialValues) {
            let found = categories.filter((cat) => cat.resource_cat_id === initialValues.resource_cat_id)
            //console.log(found)
            setSelectedCategory(found[0])
        }
    }, [categories, initialValues])


    useEffect(() => {

        if (initialValues?.resource_id) {
            const getFile = async () => {
                setLoading(true)
                let extension = initialValues?.resource_type.split('/')[1]
                let url = await getSignedUrl(config.s3.BUCKET, `public/resources/${initialValues.resource_id}.${extension}`)
                if (url) {
                    async function createFile(url, resource) {
                        if (typeof window === 'undefined') return // make sure we are in the browser
                        const response = await fetch(url)
                        //console.log(response)
                        const blob = await response.blob()
                        //console.log(blob)
                        return new File([blob], "file", { type: resource.resource_type })
                    }
                    let file = await createFile(url, initialValues)

                    setFile([file])
                }
            };
            getFile()
        }
    }, [initialValues])


    const mutation = useMutation((values) => {
        //console.log(values)
        return axiosRequest({
            endpoint: `resources`,
            method: 'post',
            gateway: config.factoryAPI.URL,
            api_key: user.api_key,
            body: values
        })
    }, {
        onSuccess: async (response, variables) => {
            if (!response.errorMessage) {
                // const file_ext = getFileExtension(file[0])
                // const file_path = `resources/${response.insertId}.${file_ext}`;
                try {

                    //console.log(variables)
                    // add thmbnail if preview image is set
                    if (preview) {
                        await Storage.put(`resources/${variables.resource_id}_preview.png`, preview,
                            {
                                progressCallback(progress) {
                                    //setProgress((progress.loaded / progress.total) * 100);
                                },
                                //contentEncoding: "base64",
                                contentType: "image/png"
                            }
                        );
                    }

                    if (slider) {
                        //const image_ext = getFileExtension(slider.type)
                        const image_ext = slider.type.split('/')[1]
                        //save image as jpeg
                        if (image_ext === "jpg") {
                            image_ext = "jpeg"
                        }
                        const slider_image_path = `resources/slider-${variables.resource_id}.${image_ext}`;
                        await Storage.put(slider_image_path, slider, {
                            progressCallback(progress) {
                                //setProgress((progress.loaded / progress.total) * 100);
                            },
                            contentType: variables.resource_silder_image
                        });
                    }


                } catch (error) {
                    console.log(error);
                } finally {
                    const string = `resource ${variables.delete ? 'deleted' : 'updated'}`
                    updateActivityLog.mutate({
                        activity_details: {
                            area: "factory",
                        },
                        activity_type: string
                    })
                    setEditResource(false)
                    refreshResources()
                    refetchCategories()
                    mutation.reset()
                }

            } else {
                console.log(response.errorMessage)
            }
        }
    })

    const submitResource = (values) => {
        //console.log(values)
        mutation.mutate({
            "update": true,
            "resource_id": values.resource_id,
            "resource_cat_id": values.resource_cat_id,
            "resource_cat_name": values.resource_cat_name,
            "resource_description": values.resource_description,
            "resource_file_name": values.resource_file_name,
            "resource_settings": [{
                "resource_promotion": values.resource_promotion,
                "resource_announcement": values.resource_announcement,
                "resource_silder_image": values.resource_silder_image,
                "resource_preview_image": preview ? true : false
            }]
        })
    };

    //set file
    const setFileUpload = (newFiles) => {
        setFile([newFiles])
    }

    //set silder image
    const setSilderUpload = (image) => {
        setSlider(image)
    }

    //set preview image
    const setPreviewUpload = (image) => {
        if (image) {
            setPreview(image)
        } else {
            setPreview()
            setFile([])
        }
    }

    return (


        <Formik
            innerRef={current => formRef.current = current} //access methods
            initialValues={initialValues}
            validationSchema={resouceValidation}
            onSubmit={submitResource}
        >
            {({ handleSubmit, errors }) => {
                // console.log(errors)
                return (
                    <GlobalDialog
                        open={editResource}
                        onClose={() => setEditResource(false)}
                        title={'Edit Resource'}
                        buttonTitle={'Edit Resource'}
                        buttonClick={handleSubmit}
                    >
                        <ResourceForm
                            edit={true}
                            fileUrl={fileUrl}
                            preview={preview}
                            categories={categories}
                            setFileUpload={setFileUpload}
                            setPreviewUpload={setPreviewUpload}
                            setSilderUpload={setSilderUpload}
                            slider={slider}
                            selectedCategory={selectedCategory}
                            openLevels={openLevels}
                            setOpenLevels={setOpenLevels}
                            file={file}
                            loading={loading}
                            setLoading={setLoading}
                        />
                        {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                            <DialogLoader
                                isLoading={mutation.isLoading}
                                mutation={mutation}
                                loadingMessage="Saving Resource..."
                                successMessage="Resource Saved"
                                closeDialog={() => {
                                    setEditResource(false)
                                    refreshResources()
                                    refetchCategories()
                                }}
                                fixed
                            />
                            : null}
                    </GlobalDialog>
                )
            }
            }
        </Formik>

    );
};

export default EditResource;
