import React from "react";
import Typography from '@mui/material/Typography';
import { Divider, Grid, List, ListItemText, Skeleton } from '@mui/material';
import config from "../../config";

const ProductDetails = ({ product, isFetching }) => {

    return (
        <>
            {!isFetching && product ?
                <Grid item xs={12}>

                    <List dense >
                        {product?.product_id &&
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2" ><strong>{config.APPNAME === "act" ? "ACT No" : config.APPNAME === "cis" ? "CIS No" : "ERP No"}</strong> : {product.product_id}</Typography>
                                }
                            />
                        }
                         {product?.product_number &&
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2" ><strong>Manufacturer No (J21)</strong> : {product.product_number}</Typography>
                                }
                            />
                        }
                        {product?.product_description &&
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2" ><strong>Description</strong> : {product.product_description}</Typography>
                                }
                            />
                        }
                        {product?.product_packet_size &&
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2" ><strong>Pack Size</strong> : {product?.product_packet_size}</Typography>
                                }
                            />
                        }
                    </List>
                </Grid>
                :
                <Grid item xs={12}>
                    <Skeleton width="40%" variant="text" animation="wave" />
                    <Skeleton width="60%" variant="text" animation="wave" />
                    <Skeleton width="90%" variant="text" animation="wave" />
                    <Skeleton width="80%" variant="text" animation="wave" />
                    <Skeleton width="100%" variant="text" animation="wave" />
                </Grid>

            }
        </>


    );
};
export default ProductDetails;