import { useContext, useEffect, useState } from 'react';
import config from '../config';
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from '../store/UserContext';
import { CategoryContext } from '../store/CategoryContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import createProductObject from "../products/opensearch/createProductObject";

export default function useEnhancedCIMPopup({ selectedProduct, setSelectedProduct }) {

    const { categories } = useContext(CategoryContext);
    const { user } = useContext(UserContext);
    const [allAccessories, setAllAccessories] = useState([])

    //get accessories
    const { data: accessories, isFetching } = useQuery({
        queryKey: ['accessories', selectedProduct?.product_cim_id],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'products/accessories',
            api_key: user.api_key,
            method: 'get',
            params: {
                id: selectedProduct?.product_cim_id
            }
        }),
        enabled: !!selectedProduct?.product_cim_id,
        retry: 1
    })

    useEffect(() => {
        if (accessories?.length && !accessories.errorMessage) {
            setAllAccessories(accessories)
        }
    }, [accessories])

    //get product full
    const { data: cim_data, isFetching: isFetchingProduct } = useQuery({
        queryKey: ['full', selectedProduct?.product_cim_id],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'products/full',
            api_key: user.api_key,
            method: 'get',
            params: {
                id: selectedProduct.product_cim_id,
            }
        }),
        enabled: !!selectedProduct?.product_cim_id,
        retry: 1
    })

    // //CIM full product
    useEffect(() => {
        //update to new product object
        const createProduct = async (cim_data) => {
            const clone = structuredClone(cim_data)
            let productObject = await createProductObject([{ ...clone, "data": "cim" }])
            setSelectedProduct(current => ({
                ...current,
                ...productObject,
                "product_image": current.product_image, //keep old images
                "product_attributes": current.product_attributes //keep old attributes
            }))
        }
        if (cim_data) {
            createProduct(cim_data)
        }

    }, [cim_data, categories])


    //  //set units 
    //  const [detailProfile, setDetailProfile] = useState(null);

    //  //fetch detail profile
    //  const get_detail_profile = useMutation((form_id) => {
    //      return axiosRequest({
    //          gateway: config.shopAPI.URL,
    //          endpoint: 'categories/parametric',
    //          api_key: user.api_key,
    //          method: 'get',
    //          params: {
    //              form_id: form_id
    //          }
    //      })
    //  }, {
    //      onSuccess: (data) => {
    //          setDetailProfile(data)
    //      }
    //  })
 
    //  useEffect(() => {
    //      if (cim_data?.Category && categories.flatCats) {
    //          //get detail profile
    //          let cat = categories.flatCats.find(category => category.ID === cim_data.Category)
    //          if (cat) {
    //              get_detail_profile.mutate(cat.DetailProfile)
    //          }
    //      }
    //  }, [cim_data?.Category, categories.flatCats])
 
    //  //add units of measure to product attributes
    //  useEffect(() => {
    //      if (cim_data?.Properties && detailProfile?.Fields) {
    //          //set unit of measure
    //          let attributes = structuredClone(cim_data.Properties)
 
    //          attributes = attributes.map((property, i) => {
    //              //find attribute in detail profile
    //              let detail = detailProfile.Fields.find((detail) => detail.Ref === property.DIN)
    //              return {
    //                  "id": i,
    //                  "code": property?.DIN,
    //                  "name": property?.Label,
    //                  "value": property?.Value,
    //                  "unit": detail?.Unit
    //              }
    //          })
 
    //          setSelectedProduct(current => ({
    //              ...current,
    //              product_attributes: attributes
    //          }))
    //      }
    //  }, [detailProfile, cim_data?.product_attributes])
 


    return {
        "isFetching": isFetching || isFetchingProduct,
        "allAccessories": allAccessories
    }
};

