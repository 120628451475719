import React from 'react';
import config from '../config';
import { Text, View } from '@react-pdf/renderer';

const PDFFooter = ({ styles, theme }) => {

    const year = new Date();

    return (
        <View style={[styles.HEADER, { backgroundColor: theme.palette.primary.main, textAlign: 'center', padding: '16px 16px 11px 16px' }]}>
            <Text style={[styles.TEXT, { color: theme.palette.primary.contrastText }]}>{`${config.APPTITLE} - copyright © ${year.getFullYear()}`}</Text>
            <Text style={[styles.TEXT, { color: theme.palette.primary.contrastText }]}> Designed &amp; Developed by Design Monkey Media ltd</Text>
            <Text style={[styles.TEXT, { color: theme.palette.primary.contrastText }]}> Powered by AWS </Text>
            <Text
                style={{
                    position: 'absolute',
                    right: 16,
                    bottom: 16,
                    fontSize: 10,
                    textAlign: 'center',
                    color: theme.palette.primary.contrastText
                }}
                render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} />
        </View>
    )
};

export default PDFFooter;  
