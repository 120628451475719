import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
    Tabs,
    Tab,
    Card,
    Grid
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Companies from '../companies/Companies';
//import Users from '../Users/Users';
//import Brands from '../Brands/Brands';
import Theme from './theme/Theme';//import Types from '../Types/Types';
import ApplicationDetails from './ApplicationDetails';
import { isMobileOnly } from 'react-device-detect';
//import ComingSoon from '../ComingSoon';
import { LayoutContext } from '../../store/LayoutContext';
import { makeStyles } from '@mui/styles';
import setLanguageText from '../../language/setLanguageText';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';
import PageContent from '../../coolantcare/PageContent';
import { UserContext } from '../../store/UserContext';
import ApplicationEmails from './ApplicationEmails';
import Brands from '../../coolantcare/brands/Brands';
import EmailTemplateViewer from '../../emails/EmailTemplateViewer';
import Reports from './reports/Reports';
import ShopBrands from '../shop/ShopBrands';
import SearchRoutesWrap from '../../routes/SearchRoutesWrap';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: isMobileOnly ? 'block' : 'flex',
        //height: 224,
    },
    dropdown: {
        width: '100%',
        padding: '10px 10px 10px 0',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: isMobileOnly ? '100%' : '240px',
        overflow: 'visible',
        //backgroundColor: theme.palette.background.default,
    },
    tabs_content: {
        width: '100%',
        minHeight: '600px',
        overflow: 'auto',
    },

}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            className={classes.tabs_content}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                //lazy load
                children
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function createProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const ApplicationSettings = () => {

    const navigate = useNavigate();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const { language } = useContext(LayoutContext);
    const { user } = useContext(UserContext)

    //change URL parameters
    const handleChange = (event, newValue) => {
        navigate(`/settings/app?tab=${newValue}`)
        setValue(parseInt(newValue));
    };

    return (
        <>
            <InnerHeaderWrap>
            </InnerHeaderWrap>
            <PageContent>
                {user.user.user_permissions.administrator ?
                    <Grid container item spacing={2} xs={12}>
                        <Grid
                            item
                            xs={12}>
                            <Card className={classes.root} >
                                <Tabs
                                    allowScrollButtonsMobile
                                    orientation={isMobileOnly ? 'horizontal' : 'vertical'}
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Application Settings Menu"
                                    className={classes.tabs}
                                >
                                    <Tab label={setLanguageText("Application Details")} {...createProps(0)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: theme => `1px solid ${theme.palette.background.paper}` }} />
                                    <Tab label={setLanguageText("Application Theme")} {...createProps(1)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: theme => `1px solid ${theme.palette.background.paper}` }} />
                                    <Tab label={setLanguageText("Application Emails")} {...createProps(2)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: theme => `1px solid ${theme.palette.background.paper}` }} />
                                    <Tab label={setLanguageText("Email Templates")} {...createProps(3)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: theme => `1px solid ${theme.palette.background.paper}` }} />
                                    <Tab label={setLanguageText("Coolant Brands")} {...createProps(4)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: theme => `1px solid ${theme.palette.background.paper}` }} />
                                    <Tab label={setLanguageText("Company Overview")} {...createProps(5)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: theme => `1px solid ${theme.palette.background.paper}` }} />
                                    <Tab label={setLanguageText("Company Activity")} {...createProps(6)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: theme => `1px solid ${theme.palette.background.paper}` }} />
                                    <Tab label={setLanguageText("Shop Brand Logos & Delivery")} {...createProps(7)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: theme => `1px solid ${theme.palette.background.paper}` }} />
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <ApplicationDetails />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Theme type="app" edit={true} />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <ApplicationEmails />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <EmailTemplateViewer />
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <Brands />
                                </TabPanel>
                                <TabPanel value={value} index={5}>
                                    <Companies />
                                </TabPanel>
                                <TabPanel value={value} index={6}>
                                    <Reports />
                                </TabPanel>
                                <TabPanel value={value} index={7}>
                                    <SearchRoutesWrap>
                                        <ShopBrands />
                                    </SearchRoutesWrap>
                                </TabPanel>
                            </Card>
                        </Grid>
                    </Grid>
                    : null}
            </PageContent>
        </>
    );
}

export default ApplicationSettings;
