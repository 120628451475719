import React from 'react';
import {Grid, Link, Typography} from '@mui/material';
import config from '../config';
import packageJson from '../../package.json';

const Footer = ({white}) => {

  const year = new Date(); 

  return (
      <Grid item  xs={12} sx={{position: white ? 'relative': 'absolute', bottom : 0, padding: '1em', textAlign : 'center', width: '100%', left : 0, right : 0}} >
         <Typography color={(theme) =>  white ? '#FFF' : theme.palette.text.secondary} variant="subtitle2">{`${config.APPTITLE} - copyright © ${year.getFullYear()}`} - v{packageJson.version}</Typography> 
         <Typography color={(theme) =>  white ? '#FFF' : theme.palette.text.secondary} variant="subtitle2">Designed &amp; Developed by 
         <Link sx={{color: (theme) =>  theme.palette.primary.main}}  title="Cambridge Applcation Developers" href="https://www.design-monkey.co.uk" rel="noreferrer" target="_blank"> Design Monkey Media ltd</Link>. 
         Powered by <Link sx={{color: (theme) =>  theme.palette.primary.main}} href='https://aws.amazon.com/' rel="noreferrer" target="_blank">AWS</Link></Typography> 
      </Grid>
  )
};

export default Footer;
