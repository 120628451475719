export default function createMainQuery({ term, type }) {

    //console.log('type', term)
    //console.log('type', type)
    //count how many word are in the term
    let words = term.split(' ').length

    // Minimum score to exclude results with a score of 0
    let minScore = 0; // Adjust this value as necessary
    // if (type === 'all') {
    //     minScore = words * 4
    // } else if (type === 'description') {
    //     minScore = words * 4
    // } else if (type === 'code') {
    //     minScore = 0
    // } else if (type === 'number') {
    //     minScore = 0
    // }


    let mainQuery = {
        "bool": {
            "must": [],
            "should": [],
            "filter": [],
        }
    };
    let highlight

    switch (type) {
        case 'all':

            mainQuery.bool.should = [
                {
                    "match_bool_prefix": {
                        "supplier_number.cleaned": {
                            "query": term,
                            "fuzziness": "auto",
                            "boost": 2.0,
                        }
                    }
                }, {
                    "match_bool_prefix": {
                        "product_code.cleaned": {
                            "query": term,
                            "fuzziness": "auto",
                            "boost": 2.0,
                        }
                    }
                },{
                    "match_bool_prefix": {
                        "product_id.cleaned": {
                            "query": term,
                            "fuzziness": "auto",
                            "boost": 2.0,
                        }
                    }
                }, {
                    "match": {
                        "description": {
                            "query": term,
                            "operator": "and"
                        }
                    }
                }
            ];
            mainQuery.bool.minimum_should_match = 1


            break;
        case 'description':


            mainQuery.bool.must.push({

                "match": {
                    "description": {
                        "query": term,
                        "operator": "and"
                    }
                }

            })

            // mainQuery.bool.should.push({
            //     "match": {
            //         "description": {
            //             "query": term,
            //             //"minimum_should_match": words,
            //             "fuzziness": 1,
            //             "operator": "and",
            //             //"analyzer": "standard"
            //         }
            //     }
            // }, {
            //     "term": {
            //         "description": {
            //             "value": term,
            //             "boost": 2.0,
            //             "operator": "and",
            //         }
            //     }
            // })
            //mainQuery.bool.minimum_should_match = 1


            break;
        case 'code':

            mainQuery.bool.should.push({
                //match creates a score based on best matches
                "match_bool_prefix": {
                    "product_code.cleaned": {
                        "query": term,
                        "fuzziness": "auto",
                        "boost": 2.0,
                    }
                }
            }, {
                "match": {
                    "product_code.keyword": {
                        "query": term,
                        "fuzziness": 0
                    }
                }
            });
            mainQuery.bool.minimum_should_match = 1

            // highlight = {
            //     "fields": {
            //       "product_code": {}
            //     }
            // }


            break;
        case 'number':

            mainQuery.bool.should.push({
                //match creates a score based on best matches
                "match_bool_prefix": {
                    "supplier_number.cleaned": {
                        "query": term,
                        "fuzziness": "auto",
                        "boost": 2.0,
                    }
                }
            }, {
                "match": {
                    "supplier_number.keyword": {
                        "query": term,
                        "fuzziness": 0
                    }
                }
            });

            mainQuery.bool.minimum_should_match = 1

            break;

        case 'erp':

            mainQuery.bool.should.push({
                //match creates a score based on best matches
                "match_bool_prefix": {
                    "product_id.cleaned": {
                        "query": term,
                        "fuzziness": "auto",
                        "boost": 2.0,
                    }
                }
            }, {
                "match": {
                    "product_id.keyword": {
                        "query": term,
                        "fuzziness": 0
                    }
                }
            });

            mainQuery.bool.minimum_should_match = 1

            break;
    }

    //console.log('mainQuery', mainQuery)


    return {
        minScore,
        mainQuery,
        highlight
    }
}

