
import * as Yup from "yup"

const userValidation = () => {
    const newUserValidation = Yup.object({
        name: Yup
            .string('Enter your name')
            .required('Name is required'),
        surname: Yup
            .string('Enter your surname')
            .required('Surname is required'),
        username: Yup
            .string('Enter your username')
            .required('Username is required')
            .min(1, 'Username must be more than 1 character')
            .max(120, 'Username must be less than 120 character')
            // username mush have no spaces, capital letters or special characters
            .test('username', 'Username must not contain spaces', value => !value.includes(' '))
            .test('username', 'Username must not contain capital letters', value => value === value.toLowerCase())
            .test('username', 'Username must not contain special characters', value => !value
                .split('')
                .some(char => 'abcdefghijklmnopqrstuvwxyz0123456789.'.indexOf(char) === -1)
            ),

        email: Yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        confirm_email: Yup.string()
            .required("You must repeat you email")
            .oneOf([Yup.ref("email"), null], "Emails must match")
        ,
        home: Yup.object({
            role: Yup.string()
                .required('Role is required'),
        }),
        // buyers: Yup.object(),
        // b_group: Yup
        //     .mixed().nullable(true)
        //     .when("buyers", {
        //         is: true,
        //         then: () => Yup.object().nullable().required("Please select a user level")
        //     }),
        // engineers: Yup.object(),
        // e_group: Yup
        //     .mixed().nullable(true)
        //     .when("engineers", {
        //         is: true,
        //         then: () => Yup.object().nullable().required("Please select a user level")
        //     }),
        // vend: Yup.object(),
        // coolantcare: Yup.object(),
        // c_group: Yup
        //     .mixed().nullable(true)
        //     .when("coolantcare", {
        //         is: true,
        //         then: () => Yup.object().nullable().required("Please select a user level")
        //     }),
        // parametric: Yup.object(),
        // p_group: Yup
        //     .mixed().nullable(true)
        //     .when("parametric", {
        //         is: true,
        //         then: () => Yup.object().required("Please select a user level").nullable()
        //     }),
        // application: Yup.object(),
        // a_group: Yup
        //     .mixed().nullable(true)
        //     .when("application", {
        //         is: true,
        //         then: () => Yup.object().required("Please select a user level").nullable()
        //     }),
        // solution: Yup.object(),
        // s_group: Yup
        //     .mixed().nullable(true)
        //     .when("solution", {
        //         is: true,
        //         then: () => Yup.object().required("Please select a user level").nullable()
        //     }),
        // fixtures: Yup.object(),
        // f_group: Yup
        //     .mixed().nullable(true)
        //     .when("fixtures", {
        //         is: true,
        //         then: () => Yup.object().required("Please select a user level").nullable()
        //     }),
        // design: Yup.object(),
        // d_group: Yup
        //     .mixed().nullable(true)
        //     .when("design", {
        //         is: true,
        //         then: () => Yup.object().required("Please select a user level").nullable()
        //     })
    });

    return newUserValidation
}
export default userValidation

