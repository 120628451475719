import config from '../config';

export function setUserParams(params) {
    if (!config.ANALYTICS || process.env.NODE_ENV !== "production") return;
    //add to google analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments)
    };
    //gtag('set', params);
    gtag('config', config.ANALYTICS, params);
};

export function trackEvent({ action, params }) {
    if (!config.ANALYTICS || process.env.NODE_ENV !== "production") return;
    //add to google analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments)
    };
    gtag('event', action, params);
};