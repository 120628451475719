import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Chip, Grid, Typography } from '@mui/material';
import TabHeader from '../../../layout/TabHeader';
import TabContent from '../../../layout/TabContent';
import setLanguageText from '../../../language/setLanguageText';
import axiosRequest from '../../../axios/axoisRequest';
import { useMutation } from 'react-query';
import config from '../../../config';
import { UserContext } from '../../../store/UserContext';
import { CompanyContext } from '../../../store/CompanyContext';
import CreditsTable from './CreditsTable';
import BuyCredits from './BuyCredits';

const CompanyCredits = () => {

    const { user } = useContext(UserContext)
    const { selectedCompany } = useContext(CompanyContext)
    const [data, setData] = useState()
    const [count, setCount] = useState()

    //get database users
    const get_credits = useMutation((id) => {
        return axiosRequest({
            gateway: config.shopAPI.URL,
            endpoint: 'credits',
            api_key: user.api_key,
            method: 'get',
            params: {
                "company_id": id
            }

        })
    }, {
        onSuccess: (data) => {
            if (!data.errerMessage) {
                setData(data)
                setCount(data.length)
            }
        }
    })

    useEffect(() => {
        if (selectedCompany.company_id) {
            get_credits.mutate(selectedCompany.company_id)
        }
    }, [selectedCompany])

    const refreshTable = useCallback(() => {
        get_users.mutate(selectedCompany.company_id)
    }, [selectedCompany])

    return (
        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        padding={1}
                    >

                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Credits")}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={'auto'}>
                            <BuyCredits />
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert
                            icon={false}
                            severity={selectedCompany.company_credits > 0 ? 'success' : 'info'}
                            sx={{ marginBottom: 1 }}
                        >
                            <Typography variant="h5">
                                {setLanguageText("Credits available")} : <Typography variant="h5" sx={{ color: theme => theme.palette.primary.main }} component="span">{selectedCompany.company_credits} </Typography>
                            </Typography>
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <CreditsTable data={data} count={count} isFetching={get_credits.isLoading} refreshTable={refreshTable} />
                    </Grid>
                </Grid>
            </TabContent>
        </>
    );
};

export default CompanyCredits;
