import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from "@mui/styles"
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import { buttonStyles } from '../styles/buttonStyles';
import { Document, Page } from 'react-pdf';
import '../../pdfWorker';

const useStyles = makeStyles(theme => ({
    header: {
        padding: theme.spacing(1),
        background: '#444',
        color: '#fff'
    },
    canvas: {
        '& .react-pdf__Document': {
            maxWidth: '100%',
            overflow: 'scroll'
        },
        '& canvas': {
            margin: 'auto',
            //idth: '100%!important',
            //height: 'auto!important'
        }
    }
}));


const ReactPDFFallback = ({ url }) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const classes = useStyles();
    const buttonStyle = buttonStyles();

    function onDocumentLoadSuccess({ numPages }) {
        //console.log(numPages)
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }


    return (
        <Box
        >
            {url && (
                <>
                    {numPages > 1 &&
                        <Grid
                            sx={{
                                //position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 10,
                                background: 'rgba(0,0,0,0.5)',
                            }}
                            container
                            item
                            xs={12}
                            alignContent="center"
                            alignItems="center"
                            justifyContent="space-around"
                        >
                            <Grid item >
                                <PrimaryBtnIcon
                                    icon={faChevronLeft}
                                    onClick={previousPage}
                                    className={buttonStyle.grey}
                                    disabled={pageNumber <= 1}

                                />
                            </Grid>
                            <Grid item >
                                <p>
                                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                </p>
                            </Grid>
                            <Grid item>
                                <PrimaryBtnIcon
                                    icon={faChevronRight}
                                    onClick={nextPage}
                                    className={buttonStyle.grey}
                                    disabled={pageNumber >= numPages}

                                />
                            </Grid>
                        </Grid>
                    }
                    <Box
                        sx={{
                            width: '100%',
                            position: 'relative',
                            overflow: 'auto',
                            height: '100%',
                            '& .textLayer, & .annotationLayer' : {
                                display: 'none'
                            }

                        }}
                    >
                        <Document
                            className={classes.canvas}
                            file={url}
                            onLoadError={(error) => {
                                //console.log(error)
                            }}
                            onLoadSuccess={onDocumentLoadSuccess}
                            renderTextLayer={false} 
                        >
                            <Page pageNumber={pageNumber} />

                        </Document>
                    </Box>


                </>
            )
            }
        </Box>
    );
};

export default ReactPDFFallback;
