import React, { useContext, useState } from "react";
import Typography from '@mui/material/Typography';
import { Box, Button, Divider, Fade, Grid, Skeleton } from '@mui/material';
import AddToBasket from "../../basket/AddToBasket";
import BrandLogo from "../../shop/reusable/BrandLogo";
import { faChevronRight, faImageSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductDetails from "./ProductDetails";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../../store/LayoutContext";
import ProductSlider from "../../reusable/ProductSlider";
import ProductTabs from "../shared/tabs/ProductTabs";
import { isMobileOnly } from "react-device-detect";
import SingleImage from "../../shop/reusable/SingleImage";
import ExportSingleProduct from "../shared/ExportSingleProduct";
import AddToFavourites from "../../favourites/AddToFavourites";
import Prices from "../shared/Prices";
import Delivery from "../shared/Delivery";

const ProductPopup = ({ productDetails, closeDialog, isFetching }) => {

    const navigate = useNavigate();
    const { app } = useContext(LayoutContext);
    const [erpDetails, setErpDetails] = useState();
    const [selectedProduct, setSelectedProduct] = useState();

    console.log("ProductPopup productDetails", productDetails);

    return (

        <Grid item container >
            <Grid item xs={12} md={6} container alignContent="center" sx={{ position: 'relative' }}>
                <Fade in={true}>
                    <Grid item xs={12}
                        sx={{
                            padding: isMobileOnly ? '1em' : '2em',
                            background: '#fff'
                        }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 16,
                                left: 16,
                                zIndex: 2,
                            }}
                        >
                            <AddToFavourites product={productDetails} big />
                        </Box>

                        {productDetails?.product_image.length > 1 ?
                            <ProductSlider images={productDetails.product_image} url="url" descrition="TYPE" />
                            : productDetails?.product_image.length === 1 ?
                                <SingleImage imageUrl={productDetails.product_image[0].url} alt={productDetails.product_name} icon="6x" padding="1em" />
                                : productDetails?.product_image.length === 0 ?
                                    <Box sx={{
                                        height: '100%',
                                        width: '100%',
                                        position: 'absolute',
                                        zIndex: 2,
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'grid',
                                        top: 0
                                    }}>
                                        <FontAwesomeIcon icon={faImageSlash} color="#ccc" size="6x" />
                                    </Box>
                                    : null
                        }
                    </Grid>
                </Fade>
            </Grid>
            <Grid container item xs={12} md={6} sx={{ padding: '1em' }} alignItems="space-between">
                <Grid container item xs={12} spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12}>
                        {productDetails ?
                            <>
                                <Typography color="text.primary" variant="h5" marginBottom="10px" marginTop="5px">{productDetails.product_name}</Typography>
                            </>
                            :
                            <Skeleton width="100%" height={80} />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            width: '250px',
                            height: isMobileOnly ? '60px' : '80px',
                            overflow: 'hidden'
                        }}>
                            {/* {!isFetching && product?.CompanyCode && <BrandLogo companyID={product.CompanyCode} />} */}
                            {productDetails ?
                                <BrandLogo product={productDetails} />
                                : null}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <ProductDetails product={full} isFetching={isFetching} erpDetails={erpDetails} /> */}
                        <ProductDetails product={productDetails} isFetching={false} />
                    </Grid>
                    <Grid item xs={12}>

                        <Grid container spacing={1 / 2}>
                            <Grid item xs={12}>
                                <Prices
                                    product={productDetails}
                                    large={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Delivery
                                    product={productDetails}
                                    large={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AddToBasket
                                    product={productDetails}
                                    area="shop"
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    <Divider variant="middle" sx={{ margin: '1em auto' }} />

                    <Grid item container spacing={1} xs={12} textAlign="right" justifyContent="flex-end">
                        <Grid item>
                            <ExportSingleProduct product={productDetails} erpDetails={erpDetails} />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={e => {

                                    let url = `/${app.selectedArea}/${app.selectedMenu}/product/${productDetails.product_cim_id}?tab=1`;

                                    if (app.selectedMenu === "account") {
                                        if (productDetails.product_dataset === "ezbase") {
                                            url = `/buyers/catalogue/product/${productDetails.product_group}?product_tab=1`;
                                        } else if (productDetails.product_dataset === "cim") {
                                            url = `/parametric/search/product/${productDetails.product_cim_id}?product_tab=1`;
                                        }
                                    }
                                    navigate(url)
                                    //navigate(`/${app.selectedArea}/${app.selectedMenu}/product/${productDetails.product_id}?tab=1`)
                                }}
                                endIcon={
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        color={'primary.contrastText'}
                                    />
                                }
                            >
                                Full Details
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ width: '100%', background: (theme) => theme.palette.cards.header }}>
                <ProductTabs
                    isFetching={false}
                    selectedProduct={productDetails}
                    setSelectedProduct={setSelectedProduct}
                    closeDialog={closeDialog}
                />
            </Box>
        </Grid>


    );
};
export default ProductPopup;