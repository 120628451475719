import React, { useContext, useEffect, useState } from "react";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Avatar, Box, CardActionArea, CardActions, Dialog, DialogContent, DialogTitle, Divider, Fade, Grid, IconButton, Skeleton, Tooltip } from '@mui/material';
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube, faDolly, faPlane, faTimes, faTruck } from "@fortawesome/pro-light-svg-icons";
import { CategoryContext } from "../../store/CategoryContext";
import { isMobileOnly } from "react-device-detect";
import AddToBasket from "../../basket/AddToBasket";
import BrandLogo from "../../shop/reusable/BrandLogo";
import ProductFullList from "./ProductFullList";
import ProductPopup from "./ProductPopup";
import { UserContext } from "../../store/UserContext";
import AddToFavourites from "../../favourites/AddToFavourites";
import { trackEvent } from "../../google_analytics/ga";
import Prices from "../shared/Prices";
import Delivery from "../shared/Delivery";
import useGetPrices from "../../hooks/useGetPrices";

const ProductGridItem = ({ product, isFetching, attributes }) => {

	const [open, setOpen] = useState(false);
	const { categories } = useContext(CategoryContext);
	const handleClose = () => setOpen(false);
	const [photo, setPhoto] = useState();
	const [noImage, setNoImage] = useState();
	const [loading, setLoading] = useState(true);
	const [productDetails, setProductDetails] = useState();
	const [has3D, setHas3d] = useState(false);
	const { user } = useContext(UserContext);
	const { isFetchingPrice } = useGetPrices({ productDetails, setProductDetails });

	const handleOpen = (product) => {
		setOpen(true)
		//add to google analytics
		trackEvent({
			action: 'view_item',
			params: {
				"currency": "GBP",
				"value": product.price_incl_discount,
				"items": [{
					"item_id": product.product_id,
					"item_name": product.product_description,
					"product_number": product.product_number,
					"product_supplier": product.product_supplier
				}]
			}
		})
	}

	useEffect(() => {
		if (product) {
			setLoading(true);
			setProductDetails(current => ({
				...current,
				...product,
			}))
		}
		return () => {
			setProductDetails()
		}
	}, [product])


	useEffect(() => {
		function getWorkingImage(images) {
			const promises = images.map((image, i) => {

				const selectedImage = image.url;
				return new Promise((resolve) => {
					const img = new Image();
					img.src = selectedImage;
					img.onload = () => resolve({ "url": selectedImage });
					img.onerror = () => resolve();
				});
			});
			Promise.all(promises).then((result) => {
				//console.log(result)
				if (result && Object.values(result).length && result[0] !== undefined) {
					//console.log(result)
					setPhoto(result[0]?.url);
					setNoImage(false);
					setLoading(false);
				} else {
					setPhoto();
					setNoImage(true);
					setLoading(false);
				}
			});
		}
		let images = []
		if (productDetails?.product_image && productDetails.product_image.length) {

			//console.log(productDetails)
			productDetails?.product_image.map((image) => {
				if (image.type === "CategoryTechincalDiagram") {
					images.push(image)
				}
				if (image.type === "Photo") {
					images.push(image)
				}
				if (image.type === "SchematicDiagram") {
					images.push(image)
				}
				if (image.viewer) {
					setHas3d(true)
				}
			})
			// let cat = productDetails?.product_image.find(item => item.type === "CategoryTechincalDiagram")
			// let photo = productDetails?.product_image.find(item => item.type === "Photo")
			// let schematic = productDetails?.product_image.find(item => item.type === "SchematicDiagram")
			// if (photo) {
			// 	images.push(photo)
			// }
			// if (schematic) {
			// 	images.push(schematic)
			// }
			// if (cat) {
			// 	images.push(cat)
			// }
			getWorkingImage(images)
		} else {
			//console.log('no images')
			//console.log(productDetails)
			//no images so fecth category image if there is one
			if (productDetails?.product_category) {
				let cat = categories.flatCats.find(category => category.ID === productDetails.product_category)
				//console.log(cat)
				if (cat?.Image) {
					images.push({ 'url': cat.Image, "type": 'CategoryTechincalDiagram' })
				}
				//update product object
				productDetails.product_image = images
				getWorkingImage(images)
			}
		}

		return () => {
			setPhoto()
			setNoImage()
			setLoading(true)
		}

	}, [productDetails?.product_image, categories])

	//add units of measure to product attributes
	useEffect(() => {
	    if (product?.product_attributes && attributes) {
			console.log(product.product_attributes)
			console.log(attributes)
	        //set unit of measure
	        let clone = structuredClone(product.product_attributes)

	        clone = clone.map((attribute, i) => {
	            //find attribute in detail profile
	            let detail = attributes.find((detail) => detail.Ref === attribute.code)
	            return {
	                ...attribute,
	                "unit": detail?.Unit
	            }
	        })
			console.log(clone)
	        setProductDetails(current => ({
	            ...current,
	            product_attributes: clone
	        }))
	    }
	}, [product?.product_attributes, attributes])

	return (
		<>
			<Card sx={{
				position: 'relative',
				height: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				background: (theme) => theme.palette.cards.main,
				zIndex: 0,
				cursor: 'pointer',
				'&:hover': {
					boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.4)',
					transition: 'box-shadow 0.3s ease-in-out',
				}
			}} >
				<Box
					sx={{
						position: 'absolute',
						top: 10,
						left: 10,
						zIndex: 2,
					}}
				>
					<AddToFavourites
						product={productDetails}
						isFetching={isFetching}
					/>
				</Box>
				<Box sx={{
					position: 'absolute',
					width: '90px',
					height: '40px',
					overflow: 'hidden',
					top: 0,
					right: 0,
					zIndex: 2,
					borderBottom: '1px solid #eeeeee29',
					borderLeft: '1px solid #eeeeee29',
					padding: '5px',
					background: '#ffffff60',
					borderRadius: '0 0 0 10px',
					cursor: 'pointer'
				}}
					onClick={() => handleOpen(productDetails)}
				>
					<BrandLogo product={productDetails} />
				</Box>
				<Box
					onClick={() => handleOpen(productDetails)}
					sx={{ cursor: 'pointer', height: "140px", width: '100%', textAlign: 'center', background: '#fff', display: 'grid', position: 'relative' }}>
					<Skeleton variant="rectangular" width={'100%'} height={140} />
					{photo && !isFetching && !loading ?
						<Fade in={photo ? true : false}>
							<Box sx={{
								position: 'absolute',
								height: '140px',
								width: '100%',
								top: 0,
								left: 0,
								background: '#fff',
								zIndex: 1,
							}}>
								<img
									src={photo}
									alt=""
									style={{ objectFit: "contain", padding: '16px', background: '#fff', cursor: "pointer", height: '140px', width: '100%' }}
									onError={e => {
										setPhoto()
									}}
									onClick={() => handleOpen(productDetails)}
								/>
								<Grid container spacing={1 / 2}
									sx={{
										position: 'absolute',
										left: '10px',
										bottom: '10px'
									}}>
									{has3D ?
										<Grid item >
											<Tooltip title={"3D Models Available"} placement="bottom" arrow>
												<Avatar
													//variant="rounded"
													sx={{
														width: '30px',
														height: '30px',
														float: 'left',
														cursor: 'pointer',
														backgroundColor: (theme) => theme.palette.primary.main,
														'& svg': {
															width: '18px',
															height: '18px'
														}
													}}
												>
													<FontAwesomeIcon icon={faCube} />
												</Avatar>
											</Tooltip>
										</Grid>
										: null}
									{productDetails?.product_stock > 0 ?
										<Grid item >
											<Tooltip title={"Warehouse Stock Available"} placement="bottom" arrow>
												<Avatar
													//variant="rounded"
													sx={{
														width: '30px',
														height: '30px',
														float: 'left',
														cursor: 'pointer',
														backgroundColor: (theme) => theme.palette.primary.main,
														'& svg': {
															width: '18px',
															height: '18px'
														}
													}}
												>
													<FontAwesomeIcon icon={faDolly} />
												</Avatar>
											</Tooltip>
										</Grid>
										: null}
									<Grid item >
										<Tooltip title={"Manufacturer Stock Available"} placement="bottom" arrow>
											<Avatar
												//variant="rounded"
												sx={{
													width: '30px',
													height: '30px',
													float: 'left',
													cursor: 'pointer',
													backgroundColor: (theme) => theme.palette.primary.main,
													'& svg': {
														width: '18px',
														height: '18px'
													}
												}}
											>
												<FontAwesomeIcon icon={faTruck} />
											</Avatar>
										</Tooltip>
									</Grid>

								</Grid>
							</Box>
						</Fade>
						: noImage && !isFetching && !loading ?
							<Fade in={noImage ? true : false}>
								<Box sx={{
									position: 'absolute',
									height: '140px',
									width: '100%',
									top: 0,
									left: 0,
									background: '#fff',
									zIndex: 1,
								}}>
									<FontAwesomeIcon size="5x" icon={faImageSlash} color="#ccc" style={{ margin: '.5em auto' }} />
								</Box>
							</Fade>
							: null}
				</Box>
				<Box
					sx={{
						display: 'block',
						height: '100%',
						position: 'relative',
						zIndex: 1,
						padding: '0.5em 1em',
						background: (theme) => theme.palette.cards.main,
						borderTop: theme => `4px solid ${theme.palette.primary.main}`,
					}}
					onClick={() => handleOpen(productDetails)}
				>

					<Typography gutterBottom variant="p" fontWeight={700} component="div">
						{isFetching ? <Skeleton /> : productDetails?.product_name}
					</Typography>
					{isFetching ?
						<>
							<Skeleton />
							<Skeleton />
							<Skeleton />
						</>
						: productDetails?.product_attributes ?
							<Grid item >
								<ProductFullList
									product={productDetails}
									visible={attributes}
									attributes={productDetails.product_attributes}
									isFetching={isFetching}
								//erpDetails={erpDetails}
								/>
							</Grid>
							: null}
				</Box>
				<CardActions sx={{ background: (theme) => theme.palette.cards.footer }}>
					<Grid container spacing={1 / 2}>
						<Grid item xs={12}>
							<Prices
								product={productDetails}
							/>
						</Grid>
						<Grid item xs={12}>
							<Delivery
								product={productDetails}
							/>
						</Grid>
						<Grid item xs={12}>
							<AddToBasket
								product={productDetails}
								area="shop"
							/>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
			{open &&
				<Dialog
					fullWidth
					maxWidth="md"
					open={open}
					onClose={handleClose}
					fullScreen={isMobileOnly}
				>
					<IconButton
						sx={{
							cursor: 'pointer',
							position: 'absolute',
							right: 8,
							top: 8,
							background: '#fff',
							padding: '10px 15px',
							zIndex: 10
						}}
						onClick={handleClose}
					>
						<FontAwesomeIcon
							aria-label="close"
							sx={{
								color: (theme) => theme.palette.grey[500],
							}}
							icon={faTimes}
						/>
					</IconButton>

					<ProductPopup productDetails={productDetails} closeDialog={handleClose} />

				</Dialog>
			}
		</>
	);
}


export default ProductGridItem;