import { Alert, Autocomplete, Button, Divider, Grid, ListItemIcon, MenuItem, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faTrash } from "@fortawesome/pro-light-svg-icons";
import GlobalDialog from "../../../reusable/GlobalDialog";
import { BasketContext } from "../../../store/BasketContext";
import config from "../../../config";
import { UserContext } from "../../../store/UserContext";
import { updateBasketTotals } from "../../../basket/helpers/updateBasketTotals";
import { useMutation } from "react-query";
import { CompanyContext } from "../../../store/CompanyContext";
import { UPDATE_BASKET } from "../../../store/BasketReducers";
import axiosRequest from '../../../axios/axoisRequest';
import DialogLoader from "../../../reusable/DialogLoader";
import { set } from "lodash";

const BuyCredits = () => {

    const { basket, dispatchBasket } = useContext(BasketContext);
    const { selectedCompany } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [loading, setloading] = useState(false);
    const [error, setError] = useState();

    const options = [
        { name: '10 Credits', count: 10, price: 10 , product_number: '3DModel Credit 10' },
        { name: '50 Credits', count: 50, price: 50, product_number: '3DModel Credit 50' },
        { name: '100 Credits', count: 100, price: 95, product_number: '3DModel Credit 100' },
        { name: '200 Credits', count: 200, price: 185, product_number: '3DModel Credit 200' },
        { name: '500 Credits', count: 500, price: 450, product_number: '3DModel Credit 500' },
        { name: '1000 Credits', count: 1000, price: 850, product_number: '3DModel Credit 1000' },
    ]

    const handleCloseDialog = () => {
        setOpen(false);
        setSelected();
        setError();
    }


    const update_basket = useMutation((basket) => {

        //console.log(basket)
        return axiosRequest({
            gateway: config.shopAPI.URL,
            endpoint: 'basket',
            api_key: user.api_key,
            method: 'patch',
            body: {
                "basket_id": basket.basket_id,
                "basket_company": selectedCompany.company_id,
                "basket_items": basket.items,
                "basket_name": basket.basket_name ? basket.basket_name : 'Basket', //if no basket id set to 1
            }
        })
    }, {
        onSuccess: (context, variables) => {
            //console.log(context.insertId)
            //console.log(variables)
            dispatchBasket({
                type: UPDATE_BASKET,
                payload: {
                    "items": variables.items,
                    "basket_id": context.insertId,
                    "basket_company": selectedCompany.company_id,
                    "basket_name": variables.basket_name ? variables.basket_name : 'Basket'
                }
            });
            setloading(false)
            handleCloseDialog()
        }
    })

    const handleBuy = (selected) => {

        if(!selected) return;

        setloading(true)
        let basket_item = {
            "price_incl_discount": selected.price,
            "price_rrp": selected.price,
            "product_area": "tokens",
            "product_id": -1,
            "product_image": "",
            "product_name": selected.name,
            "product_pack_size" : selected.count,
            "product_number": selected.product_number,
            "product_quantity": 1,
            "product_limit": 1
        }

        let currentItems = [...basket.items];
        //Check to see if product exisits in basket
        let index = currentItems.findIndex((item) => item.product_number === basket_item.product_number);
        if (index > -1) {
            //already have this item in basket
            setError("You already have this item in your basket")
            setloading(false)
            return;
        } else {
            currentItems = [...currentItems, basket_item]
        }
        //console.log(currentItems)
        sessionStorage.setItem("basket_id", basket.basket_id);
        //sessionStorage.setItem("basketProduct", JSON.stringify(currentItems));
        //Set basket state
        update_basket.mutate({ ...basket, items: currentItems })
        //calculates all totals
        updateBasketTotals(currentItems, dispatchBasket);
    }

    const updateSelected = (newValue) => {
        //console.log(newValue)
        setSelected(newValue);
    }

    return (
        <>

            <Button
                variant="contained"
                color="primary"
                startIcon={<FontAwesomeIcon icon={faCoins} />}
                onClick={() => setOpen(true)}
            >
                Buy Credits
            </Button>
            {open ?
                <GlobalDialog
                    open={open}
                    onClose={handleCloseDialog}
                    title={"Buy Credits"}
                    buttonClick={() => handleBuy(selected)}
                    buttonTitle="Add to Basket"
                    closeText="Close"
                    maxWidth="sm"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} container spacing={2}>
                            {options.map((option, index) => (
                                <Grid item xs={6} key={index}>
                                    <Typography component="p" textAlign="center" sx={{ padding: '10px 0' }}>
                                        {option.name} = £{option.price}
                                    </Typography>
                                    <Divider />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12} >
                            <Autocomplete
                                disableClearable
                                id="credit"
                                value={selected}
                                options={options}
                                getOptionLabel={option => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    option.count === value.count
                                }
                                onChange={(event, newValue) => updateSelected(newValue)}
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" label="Select Amount" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Alert severity="info">Credits can be used to purchase product downloads including 3D models.</Alert>
                        </Grid>
                        {error ?
                            <Grid item xs={12} >
                                <Alert severity="error">You already have {selected.name} in your basket.</Alert>
                            </Grid>
                            : null}
                    </Grid>
                    {loading ?
                        <DialogLoader
                            fixed={true}
                            isLoading={loading}
                            //mutation={update_user}
                            loadingMessage="Adding Credits..."
                            successMessage="Credits Added"
                        />
                        : null}
                </GlobalDialog>
                : null}
        </>
    );
};

export default BuyCredits;