import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Alert, Grid, useMediaQuery, Typography, Button } from "@mui/material"

const FileUpload = ({ dropzoneProps, handleSelectUploadedFile, isSingle, disabled }) => {

  const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    open,
    fileRejections
  } = useDropzone(dropzoneProps)

  useEffect(() => {
    //console.log(acceptedFiles)
    if (acceptedFiles.length) {
      acceptedFiles.forEach((file) => {
        //console.log(acceptedFiles)
        handleSelectUploadedFile(file)
      })
    }
  }, [acceptedFiles])

  return (
    <>
      {isMd && !isSingle ? <Grid container alignItems="center" alignContent="center"
        sx={{
          borderStyle: 'dashed',
          borderWidth: 'thick',
          padding: '8em',
          borderSpacing: 'large',
          borderColor: (theme) =>
            isDragActive ? theme.palette.success.main
              : fileRejections.length ? theme.palette.error.main
                : theme.palette.text.disabled
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Grid item xs={12}>
          <Typography component="p" variant="h4" align="center">
            Drag Files Here
          </Typography >
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography component="p" variant="subtitle1" >or</Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography component="p" variant="p" sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={open}>
            Select files from your computer
          </Typography>
        </Grid>
      </Grid>
        :
        <Grid item xs={12} >
          <Button
            variant="contained"
            color="primary"
   
            onClick={() =>  {
              //console.log('clikc')
              open()
            }}
            disabled={disabled}
            >
           { isSingle ? 'Upload file from your device' : 'Upload files from your device'}
          </Button>
        </Grid>
      }
      {fileRejections.length ? (
        <Grid item xs={12} sx={{ marginTop: '1em' }}>
          <Alert severity="error" >
            {fileRejections[0].errors[0].code === "file-too-large"
              ? "You selected an image larger than 5.2 MB, please select a smaller size image"
              : fileRejections[0].errors[0].message}
          </Alert>
        </Grid>
      ) : null}
    </>
  )
}

export default FileUpload
