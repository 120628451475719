import { Grid, TextField, ListItem, ListItemText, ListItemButton, Box, Skeleton } from "@mui/material";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { VariableSizeList } from "react-window";
import { matchSorter } from "match-sorter";
import { useWindowResize } from './../libs/useWindowResize'

const Row = ({ index, setSize, windowWidth, handleChange, option, style, selected }) => {

    const rowRef = useRef();
    const isEven = index % 2 === 0;
    useEffect(() => {
        setSize(index, rowRef.current.getBoundingClientRect().height);
    }, [setSize, index, windowWidth]);

    return (
        <Box
            ref={rowRef}
            sx={{
                backgroundColor: (theme) => isEven ? theme.palette.background.paper : theme.palette.background.default
            }}
        >
            <ListItem sx={style} component="div" disablePadding>
                <ListItemButton
                    selected={selected?.Label === option.Label}
                    onClick={(e) => handleChange(e, index, option)}
                >
                    <ListItemText primary={option.Label} />
                </ListItemButton>
            </ListItem>
        </Box>
    );
};


export default function FilterSelect({ options, selected, handleChange, isFetching, name }) {

    const listRef = useRef();
    const [filtered, setFiltered] = useState();


    const onFilterMouseUp = (e) => {
        if (e.target.value === '') {
            setFiltered(options)
            return
        }
        //console.log(e.target.value)
        const value = e.target.value;
        //console.log(value)
        let filtered = matchSorter(options, value, { keys: ['Label'] });
        //console.log(merged)
        setFiltered(filtered)

    };

    useEffect(() => {
        //console.log('options', options)
        setFiltered(options)
    }, [options])

    const sizeMap = useRef({});
    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }, []);
    const getSize = index => sizeMap.current[index] || 50;
    const [windowWidth] = useWindowResize();


    return (
        <>
            <Grid item xs={12}>
                <TextField
                    sx={{
                        backgroundColor: "background.paper",
                        '& legend': {
                            display: 'none'
                        },
                        marginBottom: '5px'
                    }}
                    fullWidth
                    size="small"
                    placeholder={`Search ${name}..`}
                    onKeyUp={onFilterMouseUp}
                    type={'text'}
                    disabled={!filtered?.length && !isFetching}
                />
                {filtered?.length ?

                    <VariableSizeList
                        ref={listRef}
                        height={filtered?.length > 5 ? 230 : filtered?.length * 50}
                        width="100%"
                        itemCount={filtered?.length}
                        itemSize={getSize}
                        itemData={filtered}
                        style={{
                            overflowX: 'hidden',
                            border: '1px solid #ccc',
                            backgroundColor: theme => theme.palette.background.paper,
                            color: theme => theme.palette.text.primary
                        }}
                    >
                        {({ data, index, style }) => (
                            <div style={style}>
                                <Row
                                    selected={selected}
                                    option={data[index]}
                                    index={index}
                                    setSize={setSize}
                                    windowWidth={windowWidth}
                                    handleChange={handleChange}
                                />
                            </div>
                        )}
                    </VariableSizeList>
                    : isFetching ?
                        <Skeleton variant="rectangular" height={230} />
                        : null
                }
            </Grid>
        </>
    );
};

