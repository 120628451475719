import React, { useEffect, useState } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import components from "./login/AmplifyComponents";
import Login from './login/Login';
import Routes from './routes/Routes';
import Header from './layout/Header';
import ACTHeader from './layout/ACTHeader';
import { StyledEngineProvider, ThemeProvider, Fade } from "@mui/material";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline'
import { ReactQueryDevtools } from "react-query/devtools";
import Footer from './layout/Footer';
import ACTFooter from './layout/ACTFooter';
import Sidebar from './menu/sideBar/Sidebar';
import { Auth } from 'aws-amplify';
import AppVersion from './version/AppVersion';
import useAppTheme from './hooks/useAppTheme';
import useCustomerDetails from './hooks/useCustomerDetails';
import ResetPassword from './login/ResetPassword';
import './pdfWorker'
import useFavourites from './hooks/useFavourites';
import { isMobileOnly } from 'react-device-detect';
import config from './config';
import MobileBottomMenu from './layout/MobileBottomMenu';
import { isMobile } from 'react-device-detect';
import CISHeader from './layout/CISHeader';
import CISFooter from './layout/CISFooter';
import DevHeader from './layout/DevHeader';
import DevFooter from './layout/DevFooter';
import useERPToken from './hooks/useERPToken';

const App = () => {

	const [login, setLogin] = useState();
	const [firstLogin, setFirstLogin] = useState(false);
	const [mobileOpen, setMobileOpen] = useState(false);
	const [fade, setFade] = useState(false);
	const { background, fullTheme } = useAppTheme();

	//get url params
	useEffect(() => {

		const urlParams = new URLSearchParams(window.location.search);
		const username = urlParams.get('u');
		const password = urlParams.get('p');
		//console.log(username, password)
		if (username && password) {
			setLogin({ username, password })
		}
		//remove params from url
		//window.history.replaceState({}, document.title, "/" + "");
	}, [window.location.search])

	const services = {
		async handleSignIn(formData) {
			let { username, password } = formData;
			//console.log(username, password)
			// custom username
			setFirstLogin(true)
			//console.log('first logging')
			username = username.toLowerCase();
			return Auth.signIn({
				username,
				password,
			})
		},
	};

	//get customer details from ERP
	useCustomerDetails();

	//get customer favourites
	useFavourites();

	//get erp token
	useERPToken();

	return (
		<>
			<StyledEngineProvider injectFirst>
				<CssBaseline />
				<AppVersion />
				{login && fullTheme &&
					<ResetPassword login={login} setLogin={setLogin} theme={fullTheme} />
				}
				{background ?
					<Fade in={fade}>
						<Box
							sx={{
								position: 'fixed',
								width: '100vw',
								width: '100%',
								height: '100vh',
								height: '100%',
								zIndex: 0,
								backgroundColor: config.APPNAME === "act" ? '#fff' : '#222'
							}}
						>
							<img
								src={background}
								style={{
									position: 'fixed',
									width: '100vw',
									height: '100vh',
									objectFit: 'cover',
									opacity: 0.2,
									zIndex: 1
								}}
								onLoad={e => setFade(true)}
								alt="Logo"
							/>
						</Box>
					</Fade>
					:
					null
				}
				{fullTheme ?
					<ThemeProvider theme={fullTheme}>
						<Fade in={true}>
							<Box
								sx={{
									position: 'relative',
									zIndex: 1,
									width: '100%',
									minHeight: '100vh',
									//backgroundColor: config.APPNAME === "act" ? '#F8F8F8' :  config.APPNAME === "cis" ? "transparent" : "#222",
									'& form': {
										paddingBottom: '10px!important'
									},
									'& [data-amplify-authenticator] [data-amplify-router]': {
										border: "none",
										borderTop: theme => `8px solid ${theme.palette.primary.main}`,
									},
									'& [data-amplify-authenticator-resetpassword] .amplify-button--primary': {
										backgroundColor: 'primary.main',
										padding: '1em',
										content: config.APPNAME === "act" ? '""' : null,
										clipPath: config.APPNAME === "act" ? 'polygon(0 0, 100% 0, 100% 0, calc(100% - 20px) 100%, 0 100%)' : null,
										paddingRight: '2.5em',
										border: 'none',
										'&:hover': {
											backgroundColor: 'primary.dark'
										}
									},
									'& form .amplify-button.amplify-button--fullwidth': {
										backgroundColor: 'primary.main',
										padding: '1em',
										content: config.APPNAME === "act" ? '""' : null,
										clipPath: config.APPNAME === "act" ? 'polygon(0 0, 100% 0, 100% 0, calc(100% - 20px) 100%, 0 100%)' : null,
										paddingRight: '2.5em',
										border: 'none',
										'&:hover': {
											backgroundColor: 'primary.dark'
										}
									},
									'.amplify-input': {
										borderColor: '#CCC',
										borderRadius: '0',
										padding: '1em'
									},
									'.amplify-tabs': {
										display: 'none'
									},
									'.amplify-label': {
										display: 'none'
									},
									'.amplify-button': {
										borderRadius: '0',
										borderColor: '#CCC!important',
										borderLeft: 'none',
										'& svg': {
											color: '#ccc'
										}
									},
									'& .amplify-button--link , & .amplify-field-group__outer-end .amplify-button': {
										color: theme => theme.palette.primary.main,
										backgroundColor: 'transparent',
										'&:hover': {
											color: theme => theme.palette.primary.dark,
											backgroundColor: 'transparent'
										}
									},
									'& [data-amplify-authenticator]': {
										height: '80vh'
									},
									'& [data-amplify-container]': {
										zIndex: 1
									}
								}}
							>
								<Authenticator
									services={services}
									components={components}
								>
									<Login
										firstLogin={firstLogin}
									//user={user}
									>
										<Fade in={true}>
											<Box sx={{
												backgroundColor: 'background.main',
												zIndex: 1,
												width: '100%',
												minHeight: '100vh',
												//paddingBottom: '85px'
											}}>
												{config.APPNAME === 'act' ?
													<>
														<ACTHeader setMobileOpen={setMobileOpen} />
														<Box sx={{
															display: isMobileOnly ? "block" : 'flex'
														}}>
															<Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} >
																<Routes />
																<ACTFooter />
															</Sidebar>
														</Box>
													</>
													: config.APPNAME === 'cis' ?
														<>
															<CISHeader setMobileOpen={setMobileOpen} />
															<Box sx={{
																display: isMobileOnly ? "block" : 'flex'
															}}>
																<Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} >
																	<Routes />
																	<CISFooter />
																</Sidebar>
															</Box>
														</>
														: config.APPNAME === 'dev' ?
															<>
																<DevHeader setMobileOpen={setMobileOpen} />
																<Box sx={{
																	display: isMobileOnly ? "block" : 'flex'
																}}>
																	<Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} >
																		<Routes />
																		<DevFooter />
																	</Sidebar>
																</Box>
															</>
															: null
												}
												{isMobileOnly ?
													<MobileBottomMenu />
													: null}
											</Box>
										</Fade>
									</Login>
								</Authenticator>
							</Box>
						</Fade>
					</ThemeProvider>
					: null}
				{/* <ReactQueryDevtools /> */}
			</StyledEngineProvider>
		</>
	);
};
export default App;
