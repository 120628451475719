import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import { UserContext } from '../store/UserContext';
import { Link } from 'react-router-dom';
import config from '../config';

export default function Welcome() {

    const { user } = useContext(UserContext);

    return (

        <>
            <Typography
                variant="h6"
                color="textPrimary"
                //textAlign="end"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    fontWeight: 600
                }}
            > Welcome back, {user?.user?.user_details?.name} {user?.user?.user_details?.surname}</Typography>
            <Typography
                variant="caption"
                color="textPrimary"
                //textAlign="end"
                sx={{

                    display: {
                        xs: 'none',
                        md: 'block'
                    },
                    '& a': {
                        color: theme => theme.palette.primary.main,
                        textDecoration: 'none',
                    }
                }}
            > Jump Back In: <Link to="/search/search">Search</Link>, <Link to="/parametric/search?q=&type=all&page=0">Parametric</Link>, 
            <Link to="/coolantcare">{ config.APPNAME === "act" ? " Lubrication" : " MWF"}</Link></Typography>
        </>

    )
}
