import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
} from '@tanstack/react-table'
import config from '../../../config'
import { UserContext } from '../../../store/UserContext'
import { CompanyContext } from '../../../store/CompanyContext'
import axiosRequest from '../../../axios/axoisRequest';
import { Alert, Box, Grid, InputBase, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { isMobileOnly } from 'react-device-detect'
import setLanguageText from '../../../language/setLanguageText'
import DownloadItem from './DownloadItem'


const OrderTable = () => {

    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);

    const columns = useMemo(() => [

        // {
        //     accessorKey: 'order_id',
        //     header: () => 'ID',
        //     footer: props => props.column.id,
        //     cell: info => info.getValue(),
        // },
        // {
        //     accessorKey: 'order_stage',
        //     header: () => 'Stage',
        //     footer: props => props.column.id,
        //     cell: info => info.getValue(),
        // },
        // {
        //     accessorKey: 'download_id',
        //     header: () => 'Download Number',
        //     footer: props => props.column.id,
        //     cell: info => info.getValue(),
        // },

        {
            header: () => 'Download Details',
            accessorKey: 'download_details',
            cell: ({ row }) => {
                //console.log(info)
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <strong>Article Number</strong>:  {row.original?.download_details?.product_details?.product_number}
                        </Grid>
                        <Grid item xs={12} >
                            <strong>Product Name</strong>: {row.original?.download_details?.product_details?.product_name}
                        </Grid>
                        <Grid item xs={12} >
                            <strong>File</strong>: {row.original?.download_details?.file_details?.Type}.{row?.original.download_details?.file_details?.FileExtension}
                        </Grid>
                    </Grid>
                )
            },
            footer: props => props.column.id,
        }, {
            name: 'Graphics',
            header: 'Image',
            accessorKey: "download_details",
            cell: ({ row }) => {
                //console.log(row.original.download_details.product_details)
                let photo = row.original.download_details.product_details?.product_image.find(item => item.type === "Photo")
                let diagram = row.original.download_details.product_details?.product_image.find(item => item.type === "SchematicDiagram")
                let cat = row.original.download_details.product_details?.product_image.find(item => item.type === "CategoryTechincalDiagram")

                return (
                    <Grid container alignContent="center" alignItems="center" >
                        <Grid item sx={{ width: '80px', height: '50px', background: '#fff', position: 'relative' }}>
                            {(photo || diagram || cat) ?
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain'
                                    }}
                                    src={photo?.url ? photo.url : diagram?.url ? diagram.url : cat.url}
                                    onError={e => { e.target.src = cat.url }}
                                />
                                : '-'}
                        </Grid>
                    </Grid>
                )
            },
        },{
            header: () => 'Brought By',
            accessorKey: 'download_user',
            cell: info => {
                //console.log(info)
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <strong>Username</strong>:  {info.row.original.download_user.user_name}
                        </Grid>
                        <Grid item xs={12} >
                            <strong>Email</strong>: {info.row.original.download_user.user_email}
                        </Grid>
                    </Grid>
                )
            },
            footer: props => props.column.id,
        },{
            header: () => 'Actions',
            accessorKey: 'download',
            cell: info => {
                //console.log(info)
                return (
                    <Grid container spacing={1}>
                        <DownloadItem file={info.row.original.download_path} />
                    </Grid>
                )
            },
            footer: props => props.column.id,
        }, {
            accessorKey: 'download_date',
            header: () => 'Remaining Days',
            footer: props => props.column.id,
            cell: info => {
                // Get the date value from info object
                const dateValue = new Date(info.getValue());
                // Calculate the current date
                const currentDate = new Date()
                // Calculate the time difference in milliseconds for just the date portion
                const timeDifference = currentDate.setHours(0, 0, 0, 0) - dateValue.setHours(0, 0, 0, 0);
                // Calculate the number of days left
                const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                // Calculate the number of days remaining
                const daysRemaining = 30 - daysLeft;
                if (daysRemaining <= 30) {
                    // Display an alert using Material-UI
                    return <Alert severity={daysRemaining < 7 ? "error" : daysRemaining < 14 ? "warning" : "info"}>You have {daysRemaining} days left to download.</Alert>;
                }
            }

        }
    ], [])

    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10, })
    const [dateFrom, setDateFrom] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)) //from 1 month ago
    const [dateTo, setDateTo] = useState(new Date()) //today
    const [count, setCount] = useState()

    const dataQuery = useQuery(['data', pageSize, pageIndex, selectedCompany?.company_id, dateFrom, dateTo],() =>
            axiosRequest({
                gateway: config.shopAPI.URL,
                endpoint: 'downloads',
                api_key: user.api_key,
                method: 'get',
                params: {
                    "company_id": selectedCompany.company_id,
                    "limit": pageSize,
                    "offset": pageSize * pageIndex,
                    //change date time to 00:00:00
                    "date_from": new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate()).toISOString(),
                    //change date to midnight on the current day
                    "date_to": new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() + 1).toISOString()
                }
            })
        ,
        {
            //notifyOnChangeProps: ['data'],
            enabled: !!selectedCompany?.company_id,
            keepPreviousData: true
        }
    )

    useEffect(() => {
        if (dataQuery?.data) {
            setCount(dataQuery?.data[0]?.order_count)
        } else {
            setCount(0)
        }
        return () => {
            setCount(0)
        }

    }, [dataQuery])


    const defaultData = React.useMemo(() => [], [])

    const pagination = React.useMemo(() => ({
        pageIndex,
        pageSize,
    }), [pageIndex, pageSize])

    //console.log(dataQuery.data)

    const table = useReactTable({
        data: dataQuery?.data ?? defaultData,
        columns,
        pageCount: dataQuery.data?.pageCount ?? -1,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
        debugTable: true,
    })

    const handleDateFrom = (date) => {
        console.log(date)
        setDateFrom(date)
    }

    const handleDateTo = (date) => {
        console.log(date)
        setDateTo(date)
    }

    return (

        <Box sx={{ width: '100%' }}>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} md={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                    >
                        <DatePicker
                            size="medium"
                            label={setLanguageText("Date From")}
                            fullWidth={isMobileOnly}
                            inputVariant="outlined"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            value={dateFrom}
                            onChange={handleDateFrom}
                            disableFuture
                            sx={{ marginRight: 2 }}
                        />
                        <DatePicker
                            size="medium"
                            label={setLanguageText("Date To")}
                            fullWidth={isMobileOnly}
                            inputVariant="outlined"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            value={dateTo}
                            onChange={handleDateTo}
                            minDate={dateFrom}
                            disableFuture
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                        component="div"
                        count={count ? count : -1}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onPageChange={(_, page) => {
                            table.setPageIndex(page)
                        }}
                        onRowsPerPageChange={e => {
                            const size = e.target.value ? Number(e.target.value) : 10
                            table.setPageSize(size)
                        }}
                    //ActionsComponent={TablePaginationActions}
                    />
                </Grid>


                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, border: '1px solid #ccc', }} aria-label="simple table">
                            <TableHead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <TableRow
                                        key={headerGroup.id}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.table.header,
                                        }}
                                    >
                                        {headerGroup.headers.map(header => {
                                            return (
                                                <TableCell key={header.id} colSpan={header.colSpan}>
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {/* {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} table={table} />
                                                        </div>
                                                    ) : null} */}
                                                        </>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableHead>
                            <TableBody>
                                {dataQuery.isFetching ?
                                    //create array from a number
                                    new Array(pageSize).fill(0).map((_, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}>

                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={36} variant='rounded' /></TableCell>
                                            </TableRow>
                                        )
                                    })

                                    : !dataQuery.isFetching && dataQuery?.data?.length > 0 ? table.getRowModel().rows.map(row => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}
                                            >
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })
                                        :

                                        <Alert sx={{ width: '100%', margin: '1em' }} severity="info">No downloads found</Alert>

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Alert sx={{ width: '100%', margin: '1em' }} severity="info">Downloads will be available for 30 days from the date of original purchase.</Alert>
            </Grid>
        </Box>
    )
}

export default OrderTable;
